interface TwitterIconProps {
  readonly fillColor?: string;
  readonly height?: number;
  readonly width?: number;
}

const TwitterIcon = ({
  fillColor = "#DC3CAA",
  height = 16,
  width = 16,
}: TwitterIconProps) => (
  <svg
    fill={ fillColor }
    height={ height }
    width={ width }
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.545 1.97773C15.9406 2.24507 15.2997 2.42065 14.6434 2.49865C15.3351 2.08495 15.8528 1.43387 16.1001 0.666731C15.4509 1.05306 14.7392 1.32381 13.9974 1.47027C13.4991 0.937147 12.8387 0.583576 12.1187 0.464527C11.3988 0.345477 10.6597 0.467619 10.0163 0.811964C9.37296 1.15631 8.86138 1.70356 8.56112 2.36865C8.26086 3.03374 8.18875 3.77939 8.35599 4.48969C7.03953 4.42371 5.75167 4.0816 4.57601 3.48559C3.40035 2.88958 2.36317 2.05298 1.53182 1.03011C1.23755 1.53555 1.08291 2.11012 1.08374 2.69498C1.08374 3.8429 1.66799 4.85702 2.55624 5.45077C2.03058 5.43422 1.51649 5.29227 1.05682 5.03673V5.0779C1.05698 5.84241 1.32153 6.58334 1.80562 7.17507C2.28971 7.7668 2.96354 8.1729 3.71286 8.32452C3.22489 8.45676 2.71323 8.47625 2.21661 8.38152C2.42788 9.03958 2.83966 9.6151 3.39429 10.0275C3.94892 10.4399 4.61863 10.6685 5.30966 10.6813C4.62287 11.2207 3.8365 11.6194 2.99552 11.8547C2.15453 12.09 1.27542 12.1572 0.408447 12.0525C1.92187 13.0258 3.68365 13.5425 5.48303 13.5408C11.5733 13.5408 14.9039 8.49552 14.9039 4.11998C14.9039 3.97748 14.8999 3.8334 14.8936 3.69248C15.5418 3.22395 16.1013 2.64354 16.5458 1.97852L16.545 1.97773Z" />
  </svg>
);

export default TwitterIcon;
