const CloseCircleFill = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00002 17.3327C4.39752 17.3327 0.666687 13.6018 0.666687 8.99935C0.666687 4.39685 4.39752 0.666016 9.00002 0.666016C13.6025 0.666016 17.3334 4.39685 17.3334 8.99935C17.3334 13.6018 13.6025 17.3327 9.00002 17.3327ZM9.00002 7.82102L6.64335 5.46352L5.46419 6.64268L7.82169 8.99935L5.46419 11.356L6.64335 12.5352L9.00002 10.1777L11.3567 12.5352L12.5359 11.356L10.1784 8.99935L12.5359 6.64268L11.3567 5.46352L9.00002 7.82102Z"
      fill="#FF453A"
    />
  </svg>
);

export default CloseCircleFill;
