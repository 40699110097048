interface GoogleChromeIcon {
  readonly fillColor?: string;
  readonly height?: number;
  readonly width?: number;
}

const GoogleChrome = ({
  fillColor = "#DC3CAA",
  height = 16,
  width = 16,
}: GoogleChromeIcon) => (
  <svg
    fill={ fillColor }
    height={ height }
    width={ width }
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.77987 15.729C3.23558 14.9436 0.583496 11.7817 0.583496 7.99992C0.583496 6.55751 0.969038 5.20534 1.64354 4.04001L5.03425 9.91338C5.41519 10.6038 5.9937 11.1647 6.69558 11.5241C7.39747 11.8835 8.19071 12.025 8.97358 11.9305L6.78066 15.729H6.77987ZM8.50016 15.9166L11.894 10.0385C12.2645 9.4231 12.4597 8.71818 12.4585 7.99992C12.4598 7.14325 12.1818 6.3095 11.6668 5.62492H16.0542C16.2902 6.37463 16.4168 7.17263 16.4168 7.99992C16.4168 12.3723 12.8725 15.9166 8.50016 15.9166ZM10.5363 9.22305C10.3236 9.57767 10.0219 9.87058 9.66121 10.0728C9.30048 10.275 8.8932 10.3794 8.47968 10.3758C8.06617 10.3722 7.66076 10.2607 7.30361 10.0523C6.94645 9.84381 6.64993 9.54569 6.44341 9.18742L6.42045 9.14784C6.21998 8.78455 6.11818 8.37508 6.12519 7.96021C6.13221 7.54534 6.24779 7.13955 6.46043 6.78324C6.67307 6.42694 6.97534 6.13256 7.33715 5.92943C7.69896 5.72629 8.10767 5.62149 8.52258 5.62546C8.93749 5.62943 9.34412 5.74204 9.70198 5.95206C10.0598 6.16208 10.3564 6.46219 10.5622 6.82249C10.768 7.1828 10.8758 7.59073 10.8748 8.00566C10.8739 8.42059 10.7643 8.82804 10.5569 9.18742L10.5363 9.22305ZM2.66716 2.64747C3.40795 1.83829 4.30923 1.19237 5.31353 0.750876C6.31783 0.309383 7.40311 0.0820104 8.50016 0.0832571C9.89013 0.0826932 11.2557 0.448242 12.4595 1.14312C13.6633 1.838 14.6629 2.83769 15.3576 4.04159H8.50016C7.72467 4.04149 6.96622 4.26914 6.31897 4.69628C5.67171 5.12342 5.16415 5.73124 4.85929 6.4443L2.66716 2.64747Z" />
  </svg>
);

export default GoogleChrome;
