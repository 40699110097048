const PlayIcon = () => (
  <svg width={ 12 } height={ 16 } fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.376 8.416.777 15.482A.5.5 0 0 1 0 15.066V.934A.5.5 0 0 1 .777.518l10.599 7.066a.5.5 0 0 1 0 .832Z"
      fill="#fff"
    />
  </svg>
);

export default PlayIcon;
